<template>
	<div class="login">
		<div class="form-wrapper">
			<div class="col-400">
				<a :href="ssoPath">
					<ActionButton>Login with SSO</ActionButton>
				</a>
			</div>
		</div>
	</div>
</template>

<script setup>
import ActionButton from '../components/ActionButton.vue'

const ssoPath = process.env.VUE_APP_URL + 'sso'
</script>

<style lang="scss" scoped>
.login {
	min-height: 100%;

	display: flex;
	flex-direction: column;
	justify-content: center;
}

.action-button {
	width: 100%;
}
</style>
