<template>
	<div class="refs">
		<div class="top">
			<div class="name">Refs</div>
			<div class="line"></div>
		</div>
		<TableView :scrollable="false">
			<template v-slot:header> Sales breakdown </template>
			<template v-slot:content>
				<TableRow
					v-for="ref in state.refs"
					:key="ref.id"
					:label="ref.name"
					:value="`$${ref.dollars}`"
					:subvalue="ref.count"
					:background="ref.percent"
				/>
			</template>
		</TableView>
	</div>
</template>

<script setup>
import api from '../api'
import TableView from '../components/TableView'
import TableRow from '../components/TableRow.vue'
import { reactive, onMounted } from 'vue'

const state = reactive({
	refs: [],
})

const loadRefs = () => {
	api.get('dashboard/refs')
		.then(({ data }) => {
			state.refs = data.refs
		})
		.catch((error) => {
			console.log(error)
		})
}
onMounted(() => {
	loadRefs()
})
</script>

<style lang="scss" scoped>
.top {
	display: flex;
	align-items: center;
	margin-bottom: 40px;
}

.name {
	@include font-h800;
	color: #ffffff;
}

.line {
	height: 2px;

	flex-grow: 1;
	margin-left: 45px;

	background: $color-grayscale-70;
}
</style>
