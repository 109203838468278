import { createRouter, createWebHistory } from 'vue-router'
import DashboardPage from './views/DashboardPage.vue'
import LoginPage from './views/LoginPage.vue'
import SsoAuthPage from './views/SsoAuthPage.vue'
import StatsPage from './views/StatsPage.vue'
import SearchPage from './views/SearchPage.vue'
import EduPage from './views/EduPage.vue'
import DiscountPage from './views/DiscountPage.vue'
import PaddlePaylinkPage from './views/PaddlePaylinkPage.vue'
import RefsPage from './views/RefsPage.vue'
import VersionsPage from './views/VersionsPage.vue'
import VersionFormPage from './views/VersionFormPage.vue'

const router = createRouter({
	history: createWebHistory(),
	base: process.env.BASE_URL,
	routes: [
		{
			path: '/',
			component: DashboardPage,
			meta: {
				requiresAuth: true,
			},
			children: [
				{
					path: '',
					redirect: 'search',
				},
				{
					path: 'stats',
					component: StatsPage,
				},
				{
					path: 'search',
					component: SearchPage,
					name: 'home',
				},
				{
					path: 'edu',
					component: EduPage,
				},
				{
					path: 'discount',
					component: DiscountPage,
				},
				{
					path: 'paddle-paylink',
					component: PaddlePaylinkPage,
				},
				{
					path: 'refs',
					component: RefsPage,
				},
				{
					path: 'versions',
					component: VersionsPage,
				},
				{
					path: 'versions/new/:product',
					component: VersionFormPage,
					props: true,
				},
				{
					path: 'versions/edit/:product/:version',
					component: VersionFormPage,
					props: true,
				},
			],
		},
		{
			path: '/login',
			name: 'login',
			component: LoginPage,
			meta: {
				redirectIfLoggedIn: true,
			},
		},
		{
			path: '/login/sso/auth',
			component: SsoAuthPage,
			meta: {
				redirectIfLoggedIn: true,
			},
		},
	],
})

router.beforeEach((to) => {
	if (to.matched.some((record) => record.meta.requiresAuth)) {
		if (JSON.parse(localStorage.getItem('state.token')) === null) {
			return { name: 'login' }
		}
	}

	if (to.matched.some((record) => record.meta.redirectIfLoggedIn)) {
		if (JSON.parse(localStorage.getItem('state.token')) !== null) {
			return { name: 'home' }
		}
	}
})

export default router
