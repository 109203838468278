<template>
	<div id="app">
		<router-view />
	</div>
</template>

<script>
export default {
	name: 'App',
}
</script>

<style lang="scss">
@import '@/assets/scss/fonts.scss';

body,
html {
	margin: 0;
	height: 100%;
	background: $color-grayscale-90;
}

p,
h1,
h2,
h3,
h4 {
	margin: 0;
}

svg {
	outline: none;
}

a {
	text-decoration: none;
}

#app {
	height: 100%;
}

.col-400 {
	max-width: 400px;

	padding: 0 8%;
	margin: 0 auto;
}

.col-1600 {
	max-width: 1600px;

	padding: 0 5%;
	margin: 0 auto;
}

.copy-helper {
	position: fixed;
	left: 0;
	top: 0;

	opacity: 0;
}
</style>
