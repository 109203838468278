<template>
	<div class="discount">
		<div class="top">
			<div class="name">Paddle paylink</div>
			<div class="line"></div>
		</div>
		<form class="form" @submit.prevent="submit">
			<SelectInput
				id="product"
				type="number"
				label="Product:"
				v-model="state.product"
				:options="productOptions"
				@update:modelValue="state.generatedUrl = null"
			/>
			<TextInput
				id="seats"
				v-model.trim="state.seats"
				type="number"
				label="Seats:"
				placeholder="1"
				:form="state.form"
				:required="true"
				@update:modelValue="state.generatedUrl = null"
			/>
			<TextInput
				id="price"
				v-model.trim="state.price"
				type="number"
				step="0.01"
				label="Price per seat:"
				:placeholder="pricePlaceholder"
				:form="state.form"
				:required="true"
				@update:modelValue="state.generatedUrl = null"
			/>
			<ActionButton :loading="state.form.loading">{{ state.generatedUrl ? 'Copied!' : 'Generate' }}</ActionButton>
		</form>
	</div>
</template>

<script setup>
import FormHandler from '../helpers/FormHandler'
import SelectInput from '../components/SelectInput.vue'
import ActionButton from '../components/ActionButton.vue'
import TextInput from '../components/TextInput.vue'
import { copyToClipboard } from '../helpers/utils'
import { reactive, computed, watchEffect } from 'vue'
import { useStore } from 'vuex'

const store = useStore()

const state = reactive({
	product: null,
	seats: null,
	price: null,
	generatedUrl: null,
	form: new FormHandler(),
})

const products = computed(() => {
	return store.state.products.filter((product) => product.public && product.paddle_id !== null)
})
const productOptions = computed(() => {
	return products.value.map((product) => {
		return {
			name: product.name,
			value: product.id,
		}
	})
})

const pricePlaceholder = computed(() => {
	if (!state.product) {
		return ''
	}
	return (products.value.find((product) => product.id === state.product).base_price / 100).toString()
})

const submit = () => {
	if (state.generatedUrl) {
		copyToClipboard(state.generatedUrl)
		return
	}
	if (state.form.loading) {
		return
	}
	state.form
		.submit('dashboard/paddle-paylink', {
			product: state.product,
			seats: state.seats,
			price: state.price,
		})
		.then(({ data }) => {
			state.generatedUrl = data.url
			copyToClipboard(state.generatedUrl)
		})
		.catch((error) => {
			console.log(error)
		})
}

watchEffect(() => {
	if (products.value.length > 0) {
		state.product = products.value[0].id
	}
})
</script>

<style lang="scss" scoped>
.top {
	display: flex;
	align-items: center;
	margin-bottom: 40px;
}

.name {
	@include font-h800;
	color: #ffffff;
}

.line {
	height: 2px;

	flex-grow: 1;
	margin-left: 45px;

	background: $color-grayscale-70;
}

.select-input {
	margin-bottom: 20px;
}

.action-button {
	margin-top: 30px;
}

.input-group {
	display: flex;
}

.text-input {
	width: 100px;

	margin-right: 20px;
}
</style>
