<template>
	<div class="stats">
		<div class="product">
			<div class="top">
				<div class="name">
					{{ stats !== null ? stats.product : 'Stats' }}
				</div>
				<div class="line"></div>
				<SelectInput
					id="product"
					type="number"
					label="Product:"
					v-model="state.product"
					:options="productOptions"
					@input="state.generatedUrl = null"
				/>
			</div>
			<div class="loading" v-if="state.loading">
				<div class="spinner">
					<div class="bounce1"></div>
					<div class="bounce2"></div>
					<div class="bounce3"></div>
				</div>
			</div>
			<div
				v-masonry
				gutter="50"
				item-selector=".grid-item"
				class="grid"
				v-if="stats !== null"
				:class="{ '-show': !state.loading }"
			>
				<TableView class="grid-item" v-masonry-tile>
					<template v-slot:header> <span class="icon">📋</span>Summary </template>
					<template v-slot:content>
						<TableRow label="Licenses:" :value="stats.licenses_count" />
						<TableRow label="Licenses (used in last month):" :value="stats.licenses_count_month" />
						<TableRow label="Instances:" :value="stats.instances_count" />
						<TableRow label="Instances (used in last month):" :value="stats.instances_month_count" />
						<TableRow
							label="Non-upgrade refunds:"
							:value="`${round(stats.non_upgrade_refunds_percent, 1)}%`"
							:subvalue="stats.non_upgrade_refunds_count"
						/>
						<TableRow
							label="EN instead of translation:"
							:value="`${round(stats.en_instead_of_translation_percent, 1)}%`"
							:subvalue="stats.en_instead_of_translation_count"
						/>
						<TableRow
							label="Edu licenses:"
							:value="`${round(stats.edu_licenses_percent, 1)}%`"
							:subvalue="stats.edu_licenses_count"
						/>
						<TableRow label="Seat upgrades:" :value="stats.seat_upgrades_count" />
						<TableRow
							label="Not expired updates:"
							:value="`${round(stats.not_expired_licenses_percent, 1)}%`"
							:subvalue="stats.not_expired_licenses_count"
						/>
						<TableRow
							label="Not expired updates (used in last month):"
							:value="`${round(stats.not_expired_month_licenses_percent, 1)}%`"
							:subvalue="stats.not_expired_month_licenses_count"
						/>
					</template>
				</TableView>
				<TableView class="grid-item" v-masonry-tile v-if="stats.seats.length > 0">
					<template v-slot:header> <span class="icon">👨‍💻</span>Seats </template>
					<template v-slot:content>
						<TableRow
							:label="seats.seats"
							:value="`${round(seats.percent, 1)}%`"
							:subvalue="seats.count"
							v-for="seats in stats.seats"
							:key="seats.seats"
							:background="seats.percent"
						/>
					</template>
				</TableView>
				<TableView
					class="grid-item"
					v-masonry-tile
					v-if="stats.instances_month.length > 0"
					label="Last 30 days"
				>
					<template v-slot:header> <span class="icon">🙋‍♂️</span>Active instances </template>
					<template v-slot:content>
						<TableRow
							:label="instances.active_seats"
							:value="`${round(instances.percent, 1)}%`"
							:subvalue="instances.count"
							v-for="instances in stats.instances_month"
							:key="instances.seats"
							:background="instances.percent"
						/>
					</template>
				</TableView>
				<TableView
					class="grid-item"
					v-masonry-tile
					v-if="stats.app_versions_month.length > 0"
					label="Last 30 days"
				>
					<template v-slot:header> <span class="icon">🚀</span>App version </template>
					<template v-slot:content>
						<TableRow
							:label="version.version"
							:value="`${round(version.percent, 1)}%`"
							:subvalue="version.count"
							v-for="version in stats.app_versions_month"
							:key="version.version"
							:background="version.percent"
						/>
					</template>
				</TableView>
				<TableView
					class="grid-item"
					v-masonry-tile
					v-if="stats.os_versions_month.length > 0"
					label="Last 30 days"
					@toggle-detailed="rerenderGrid()"
				>
					<template v-slot:header> <span class="icon">🖥</span>OS version </template>
					<template v-slot:content>
						<TableRow
							:label="version.version"
							:value="`${round(version.percent, 1)}%`"
							:subvalue="version.count"
							v-for="version in stats.os_versions_month"
							:key="version.version"
							:background="version.percent"
						/>
					</template>
					<template v-slot:detailed-content>
						<TableRow
							:label="version.version"
							:value="`${round(version.percent, 1)}%`"
							:subvalue="version.count"
							v-for="version in stats.os_versions_month_detailed"
							:key="version.version"
							:background="version.percent"
						/>
					</template>
				</TableView>
				<TableView class="grid-item" v-masonry-tile v-if="stats.languages.length > 0" label="Last 30 days">
					<template v-slot:header> <span class="icon">💬</span>App language </template>
					<template v-slot:content>
						<TableRow
							:label="language.name"
							:value="`${round(language.percent, 1)}%`"
							:subvalue="language.count"
							v-for="language in stats.languages"
							:key="language.code"
							:background="language.percent"
						/>
					</template>
				</TableView>
				<TableView class="grid-item" v-masonry-tile v-if="stats.os_languages.length > 0" label="Last 30 days">
					<template v-slot:header> <span class="icon">🌐</span>OS language </template>
					<template v-slot:content>
						<TableRow
							:label="language.code"
							:value="`${round(language.percent, 1)}%`"
							:subvalue="language.count"
							v-for="language in stats.os_languages"
							:key="language.code"
							:background="language.percent"
						/>
					</template>
				</TableView>
				<TableView class="grid-item" v-masonry-tile v-if="stats.sale_languages.length > 0" label="Last 30 days">
					<template v-slot:header> <span class="icon">📠</span>Sale language </template>
					<template v-slot:content>
						<TableRow
							:label="language.name"
							:value="`${round(language.percent, 1)}%`"
							:subvalue="language.count"
							v-for="language in stats.sale_languages"
							:key="language.code"
							:background="language.percent"
						/>
					</template>
				</TableView>
			</div>
		</div>
	</div>
</template>

<script setup>
import api from '../api'
import SelectInput from '../components/SelectInput.vue'
import TableRow from '../components/TableRow.vue'
import TableView from '../components/TableView'
import { reactive, computed, watchEffect, ref, inject } from 'vue'
import { useStore } from 'vuex'

const redrawVueMasonry = inject('redrawVueMasonry')

const store = useStore()

const stats = ref(null)

const state = reactive({
	product: null,
	loading: true,
})
const products = computed(() => {
	return store.state.products
})
const productOptions = computed(() => {
	return products.value.map((product) => {
		return {
			name: product.name,
			value: product.id,
		}
	})
})

const loadStats = (id) => {
	state.loading = true
	api.get(`dashboard/stats/${id}`)
		.then(({ data }) => {
			stats.value = data.stats
			state.loading = false
		})
		.catch((error) => console.log(error))
}
const round = (value, precision) => {
	const multiplier = Math.pow(10, precision || 0)
	return Math.round(value * multiplier) / multiplier
}
const rerenderGrid = () => {
	setTimeout(() => {
		redrawVueMasonry()
	}, 0)
}

watchEffect(() => {
	if (products.value.length > 0) {
		state.product = products.value[0].id
	}
})

watchEffect(() => {
	if (state.product !== null) {
		loadStats(state.product)
	}
})
</script>

<style lang="scss" scoped>
.product {
	padding-bottom: 100px;
}

.top {
	display: flex;
	align-items: center;
	margin-bottom: 40px;
}

.name {
	@include font-h800;
	color: #ffffff;
}

.line {
	height: 2px;

	flex-grow: 1;
	margin-left: 45px;

	background: $color-grayscale-70;
}

.select-input {
	margin-left: 30px;
	margin-top: -30px;
}

.loading {
	width: 100%;
	height: 300px;

	display: flex;
	justify-content: center;
	align-items: center;
}

.spinner {
	width: 70px;
	text-align: center;
}

.spinner > div {
	width: 18px;
	height: 18px;
	background-color: $color-grayscale-50;

	border-radius: 100%;
	display: inline-block;
	-webkit-animation: sk-bouncedelay 1.4s infinite ease-in-out both;
	animation: sk-bouncedelay 1.4s infinite ease-in-out both;
}

.spinner .bounce1 {
	animation-delay: -0.32s;
}

.spinner .bounce2 {
	animation-delay: -0.16s;
}

@keyframes sk-bouncedelay {
	0%,
	80%,
	100% {
		-webkit-transform: scale(0);
		transform: scale(0);
	}
	40% {
		-webkit-transform: scale(1);
		transform: scale(1);
	}
}

.icon {
	margin-right: 10px;
}

.grid {
	opacity: 0;

	&.-show {
		opacity: 1;
	}
}

.grid-item {
	width: calc(33.3333% - 33.333333333px);
	margin-bottom: 50px;

	@media (max-width: 1650px) {
		width: calc(50% - 25px);
	}
}
</style>
