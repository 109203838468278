<template>
	<div class="table-row">
		<span class="label">{{ props.label }}</span>
		<span class="value" v-if="props.value !== null"
			><span class="subvalue" v-if="props.subvalue">({{ props.subvalue }})</span>{{ props.value }}</span
		>
		<slot></slot>
		<div class="background" :style="{ width: `${props.background}%` }" v-if="props.background"></div>
	</div>
</template>

<script setup>
import { defineProps } from 'vue'

const props = defineProps({
	label: [String, Number],
	value: {
		type: [String, Number],
		default: null,
	},
	subvalue: {
		type: [String, Number],
		default: null,
	},
	background: {
		type: Number,
		default: null,
	},
})
</script>

<style lang="scss" scoped>
.table-row {
	height: 50px;

	position: relative;
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 0 25px;

	border-bottom: 2px solid $color-grayscale-70;

	&:last-of-type {
		border-bottom: none;
	}
}

.label {
	position: relative;
	z-index: 2;

	font-size: 14px;
	font-weight: $medium;
	color: $color-grayscale-20;
}

.value {
	position: relative;
	z-index: 2;
	margin-left: auto;

	font-size: 16px;
	font-weight: $bold;
	color: #ffffff;

	.subvalue {
		margin-right: 10px;

		font-weight: $regular;
		color: $color-grayscale-20;
	}
}

.background {
	height: 100%;

	position: absolute;
	left: 0;
	top: 0;
	z-index: 1;

	background: $color-grayscale-90;
}
</style>
