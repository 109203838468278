import { createStore } from 'vuex'
import api from './api'

const getDefaultState = () => {
	return {
		token: null,
		user: {},
		products: [],
	}
}

const store = createStore({
	state() {
		return getDefaultState()
	},
	mutations: {
		resetStore(state) {
			Object.assign(state, getDefaultState())
		},
		initStore(state) {
			if (localStorage.getItem('state.token')) {
				state.token = JSON.parse(localStorage.getItem('state.token'))
			}
		},
		setUser(state, user) {
			state.user = user
		},
		setProducts(state, products) {
			state.products = products
		},
		setToken(state, token) {
			state.token = token
		},
	},
})

store.subscribe((_, state) => {
	if (state.token !== null) {
		api.defaults.headers.common.Authorization = `Bearer ${state.token}`
	} else {
		api.defaults.headers.common.Authorization = ''
	}
	localStorage.setItem('state.token', JSON.stringify(state.token))
})

export default store
