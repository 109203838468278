<template>
	<div class="edu">
		<div class="top">
			<div class="name">Edu discount</div>
			<div class="line"></div>
		</div>
		<form class="form" @submit.prevent="submit">
			<SelectInput
				id="product"
				type="number"
				label="Product:"
				v-model="state.product"
				:options="productOptions"
				@update:modelValue="state.generatedUrl = null"
			/>
			<ActionButton :loading="state.form.loading">{{ state.generatedUrl ? 'Copied!' : 'Generate' }}</ActionButton>
		</form>
	</div>
</template>

<script setup>
import FormHandler from '../helpers/FormHandler'
import SelectInput from '../components/SelectInput.vue'
import ActionButton from '../components/ActionButton.vue'
import { copyToClipboard } from '../helpers/utils'
import { reactive, watchEffect, computed } from 'vue'
import { useStore } from 'vuex'

const store = useStore()

const state = reactive({
	product: null,
	generatedUrl: null,
	form: new FormHandler(),
})

const products = computed(() => {
	return store.state.products.filter((product) => product.public)
})
const productOptions = computed(() => {
	return products.value.map((product) => {
		return {
			name: product.name,
			value: product.id,
		}
	})
})

const submit = () => {
	if (state.generatedUrl) {
		copyToClipboard(state.generatedUrl)
		return
	}
	if (state.form.loading) {
		return
	}
	state.form
		.submit('dashboard/discount', {
			product: state.product,
			amount: 30,
			type: 'percentage',
			edu: true,
		})
		.then(({ data }) => {
			state.generatedUrl = data.url
			copyToClipboard(state.generatedUrl)
		})
		.catch((error) => {
			console.log(error)
		})
}

watchEffect(() => {
	if (products.value.length > 0) {
		state.product = products.value[0].id
	}
})
</script>

<style lang="scss" scoped>
.top {
	display: flex;
	align-items: center;
	margin-bottom: 40px;
}

.name {
	@include font-h800;
	color: #ffffff;
}

.line {
	height: 2px;

	flex-grow: 1;
	margin-left: 45px;

	background: $color-grayscale-70;
}

.select-input {
	margin-bottom: 20px;
}

.action-button {
	margin-top: 30px;
}
</style>
