<template>
	<div class="text-input">
		<div class="label-container">
			<label class="label" :class="{ '-action': hasLabelActionSlot }" :for="id" v-if="label">{{ label }}</label>
			<slot name="label-action"></slot>
		</div>
		<input
			class="input"
			:class="{
				'-error': form.hasError(id),
				'-disabled': disabled,
				'-small': small,
			}"
			:id="id"
			:value="modelValue"
			:type="type"
			:placeholder="placeholder"
			:disabled="isDisabled"
			:required="required"
			:autofocus="autofocus"
			:autocomplete="autocomplete"
			:maxlength="maxlength"
			:tabindex="tabindex"
			:step="step"
			@input="onInput"
			@focus="$emit('focus', $event)"
			@blur="$emit('blur', $event)"
			@keyup.enter.stop="$emit('enterPress', $event)"
			@keyup.esc="$emit('escPress', $event)"
			ref="inputRef"
		/>
		<p class="error">{{ form.getError(id) }}</p>
	</div>
</template>

<script setup>
import { computed, onMounted, onActivated, useSlots, defineEmits, ref, defineProps } from 'vue'

const slots = useSlots()
const emit = defineEmits()
const props = defineProps({
	label: {
		type: String,
		default: null,
	},
	id: {
		type: String,
		default: '',
	},
	modelValue: {
		type: [String, Number],
		default: '',
	},
	placeholder: {
		type: String,
		default: '',
	},
	type: {
		type: String,
		default: 'text',
	},
	disabled: {
		type: Boolean,
		default: false,
	},
	required: {
		type: Boolean,
		default: false,
	},
	autofocus: {
		type: Boolean,
		default: false,
	},
	form: {
		type: Object,
		default: null,
	},
	small: {
		type: Boolean,
		default: false,
	},
	autoselect: {
		type: Boolean,
		default: false,
	},
	autocomplete: {
		type: String,
		default: null,
	},
	maxlength: {
		type: String,
		default: null,
	},
	tabindex: {
		type: String,
		default: null,
	},
	step: {
		type: String,
		default: null,
	},
})

const inputRef = ref(null)

const isDisabled = computed(() => {
	return props.disabled || props.form.loading
})
const hasLabelActionSlot = computed(() => {
	return !!slots['label-action']
})

const onInput = (event) => {
	emit('update:modelValue', event.target.value)
	props.form.clearError(props.id)
}
const focus = () => {
	inputRef.value.focus()
}
onMounted(() => {
	if (props.autofocus) {
		focus()
	}
	if (props.autoselect) {
		focus()
		inputRef.setSelectionRange(0, props.modelValue.length)
	}
})
onActivated(() => {
	if (props.autofocus) {
		focus()
	}
	if (props.autoselect) {
		focus()
		inputRef.setSelectionRange(0, props.modelValue.length)
	}
})
</script>

<style lang="scss" scoped>
.text-input {
	display: flex;
	flex-direction: column;
	margin-bottom: 8px;

	&:last-of-type {
		margin-bottom: 0;
	}
}

.label-container {
	display: flex;
	justify-content: space-between;
}

.label {
	padding-bottom: 8px;
	flex-grow: 1;

	@include font-h100;
	color: #ffffff;

	&.-action {
		margin-right: 25px;
	}
}

.input {
	height: 48px;

	padding: 0 16px;
	box-sizing: border-box;

	@include font;
	color: #ffffff;

	border: 1px solid $color-grayscale-80;
	border-radius: 8px;
	background: $color-grayscale-80;

	outline: none;
	appearance: none;
	transition: all 0.1s ease;

	&:hover:not(:disabled) {
		border-color: $color-primary;
	}

	&::placeholder {
		color: $color-grayscale-50;
	}

	&:not(:placeholder-shown):not(:focus) {
		border-color: $color-grayscale-70;
		background: $color-grayscale-90;
	}

	&:focus {
		border-color: $color-primary;
		background: $color-grayscale-90;
	}

	&:disabled {
		background: #ffffff;
	}

	&.-disabled {
		background: $color-grayscale-05;
	}

	&.-small {
		height: 30px;
		padding: 0 6px;
		border-radius: 4px;
	}

	&.-error {
		border-color: $color-warning;

		&:not(:placeholder-shown):not(:focus) {
			border-color: $color-warning;
		}

		&:hover:not(:disabled) {
			border-color: $color-warning;
		}

		&:focus {
			border-color: $color-warning;
		}
	}

	&::-webkit-outer-spin-button,
	&::-webkit-inner-spin-button {
		-webkit-appearance: none;
		margin: 0;
	}
}

.error {
	min-height: 20px;

	margin-top: 5px;

	@include font-small;
	color: $color-warning;
}
</style>
