<template>
	<aside class="sidebar">
		<div class="logo">Legit</div>
		<ul class="menu" v-if="user.abilities">
			<li class="item" v-if="canUser('stats')">
				<router-link class="link" to="/stats">📋&nbsp;&nbsp;Stats</router-link>
			</li>
			<li class="item" v-if="canUser('search')">
				<router-link class="link" to="/search">🔍&nbsp;&nbsp;Search</router-link>
			</li>
			<li class="item" v-if="canUser('discount')">
				<router-link class="link" to="/edu">👨🏻‍🎓&nbsp;&nbsp;Edu discount</router-link>
			</li>
			<li class="item" v-if="canUser('discount')">
				<router-link class="link" to="/discount">💸&nbsp;&nbsp;Discount</router-link>
			</li>
			<li class="item" v-if="canUser('paddle-paylink')">
				<router-link class="link" to="/paddle-paylink">🔗&nbsp;&nbsp;Paddle paylink</router-link>
			</li>
			<li class="item" v-if="canUser('refs')">
				<router-link class="link" to="/refs">📊&nbsp;&nbsp;Refs</router-link>
			</li>
			<li class="item" v-if="canUser('versions')">
				<router-link class="link" to="/versions">💡&nbsp;&nbsp;Versions</router-link>
			</li>
			<li class="item">
				<span class="link" @click="logout">🚪&nbsp;&nbsp;Log out</span>
			</li>
		</ul>
	</aside>
</template>

<script setup>
import api from '../api'
import { computed } from 'vue'
import { useRouter } from 'vue-router'
import { useStore } from 'vuex'

const router = useRouter()
const store = useStore()

const user = computed(() => {
	return store.state.user
})

const canUser = (scope) => {
	return user.value.abilities.includes(scope)
}

const logout = () => {
	api.post('dashboard/logout')
		.then(() => {
			store.commit('setToken', null)
			router.push({ name: 'login' })
		})
		.catch((error) => {
			console.log(error)
		})
}
</script>

<style lang="scss" scoped>
.logo {
	padding-left: 25px;

	@include font-h800;
	color: #ffffff;
}

.menu {
	list-style: none;
	padding: 0;
	margin-top: 50px;
}

.item {
	margin-bottom: 15px;

	&:last-of-type {
		margin-bottom: 0;
	}
}

.link {
	width: 200px;
	height: 50px;

	display: flex;
	align-items: center;
	padding-left: 25px;
	box-sizing: border-box;
	margin-bottom: 5px;

	font-weight: $medium;
	font-size: 17px;
	color: #ffffff;
	line-height: 0;

	border-radius: 20px;
	cursor: pointer;

	&.router-link-active:not(.-home),
	&.-active {
		background: $color-primary;
	}
}
</style>
