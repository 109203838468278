<template>
	<div class="dashboard">
		<div class="loading" :class="{ '-show': state.loading }"></div>
		<div class="col-1600">
			<SideBar />
			<div class="router-view">
				<router-view></router-view>
			</div>
		</div>
	</div>
</template>

<script setup>
import { computed, reactive, watchEffect } from 'vue'
import { useStore } from 'vuex'

import SideBar from '../components/SideBar.vue'

const store = useStore()

const state = reactive({
	loading: true,
})

const user = computed(() => store.state.user)

watchEffect(() => {
	if (Object.keys(user.value).length > 0) {
		state.loading = false
	}
})
</script>

<style lang="scss" scoped>
.dashboard {
	padding: 75px 0;
}

.col-1600 {
	display: flex;
}

.sidebar {
	margin-right: 100px;
}

.router-view {
	flex-grow: 1;
}

.loading {
	width: 100%;
	height: 100%;

	position: absolute;
	left: 0;
	top: 0;
	z-index: 9999;
	display: flex;
	justify-content: center;
	align-items: center;

	background: $color-grayscale-90;

	opacity: 0;
	visibility: hidden;
	pointer-events: none;
	transition: all 0.3s ease;

	&.-show {
		opacity: 1;
		visibility: visible;
	}
}
</style>
