import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import api from './api'
import { VueMasonryPlugin } from 'vue-masonry/src/masonry.plugin.js'

import { useStore } from 'vuex'
import { useRoute, useRouter } from 'vue-router'

const app = createApp({
	extends: App,
	beforeCreate() {
		const store = useStore()
		const route = useRoute()
		const router = useRouter()
		store.commit('initStore')
		if (store.state.token !== null) {
			api.defaults.headers.common.Authorization = `Bearer ${store.state.token}`
			api.get('dashboard/user')
				.then(({ data }) => {
					store.commit('setUser', data.user)
					store.commit('setProducts', data.products)
				})
				.catch((error) => {
					console.log(error)
					api.defaults.headers.common.Authorization = ''
					store.commit('resetStore')
					if (router.path !== '/login') {
						router.replace(`/login?redirect=${route.fullPath}`)
					}
				})
		}
	},
})

app.use(store)
app.use(router)

app.use(VueMasonryPlugin)

app.mount('#app')
