<template>
	<div class="select-input">
		<label class="label" :for="id" v-if="label">{{ label }}</label>
		<select class="input" @input="onInput">
			<option
				v-for="option in options"
				:value="option.value"
				:selected="option.value === modelValue"
				:key="option.value"
			>
				{{ option.name }}
			</option>
		</select>
	</div>
</template>

<script setup>
import { defineProps, defineEmits } from 'vue'

const emit = defineEmits()
const props = defineProps({
	label: {
		type: String,
		default: null,
	},
	type: {
		type: String,
		default: 'text',
	},
	id: {
		type: String,
		default: '',
	},
	modelValue: {
		type: [String, Number],
	},
	options: {
		type: Array,
	},
})

const onInput = ({ target }) => {
	if (props.type === 'number') {
		emit('update:modelValue', parseInt(target.value))
		return
	}
	emit('update:modelValue', target.value)
}
</script>

<style lang="scss" scoped>
.label {
	display: block;
	margin-bottom: 8px;

	@include font-h100;
	color: #ffffff;

	&.-action {
		margin-right: 25px;
	}
}

.input {
	padding: 11px 35px 11px 20px;

	@include font;
	color: #ffffff;

	border: 1px solid $color-grayscale-80;
	border-radius: 8px;
	background: $color-grayscale-80;
	background-image: url('../assets/img/20-Chevron-down.svg');
	background-repeat: no-repeat;
	background-position: calc(100% - 5px) center;

	cursor: pointer;
	outline: none;
	appearance: none;
	transition: box-shadow 0.1s ease, border 0.05s ease;

	&:hover:not(:disabled) {
		border-color: $color-primary;
	}

	&:focus {
		border-color: $color-primary;
	}
}
</style>
