<template>
	<div class="table">
		<div class="header">
			<slot name="header"></slot>
			<div class="label" v-if="props.label" :style="labelStyle">
				{{ props.label }}
			</div>
			<label class="switch" v-if="hasDetailedContentSlot">
				<input class="checkbox" type="checkbox" v-model="state.switchState" @input="$emit('toggle-detailed')" />
				<span class="slider"></span>
			</label>
		</div>
		<div class="container-content" :class="{ '-scrollable': props.scrollable }">
			<slot name="content" v-if="state.switchState === false"></slot>
			<slot name="detailed-content" v-if="state.switchState === true"></slot>
		</div>
	</div>
</template>

<script setup>
import { defineProps, reactive, computed, useSlots } from 'vue'

const slots = useSlots()
const props = defineProps({
	label: {
		type: [String, Number],
		default: null,
	},
	labelColor: {
		type: String,
		default: null,
	},
	scrollable: {
		type: Boolean,
		default: true,
	},
})
const state = reactive({
	switchState: false,
})

const hasDetailedContentSlot = computed(() => {
	return !!slots['detailed-content']
})
const labelStyle = computed(() => {
	if (props.labelColor) {
		return {
			background: props.labelColor,
		}
	}
	return {}
})
</script>

<style lang="scss" scoped>
.table {
	box-sizing: border-box;
	overflow: hidden;

	border-radius: 20px;
	border: 2px solid $color-grayscale-70;
	box-shadow: $box-shadow;
	background: $color-grayscale-80;
}

.header {
	height: 60px;

	display: flex;
	align-items: center;
	padding: 0 25px;

	font-weight: $bold;
	font-size: 18px;
	color: #ffffff;
	background: $color-grayscale-70;
}

.container-content {
	&.-scrollable {
		max-height: 650px;
		overflow-y: auto;
	}
}

.label {
	padding: 5px 7px;
	margin-left: auto;

	font-size: 11px;
	text-transform: uppercase;
	letter-spacing: 0.8px;
	background-color: $color-grayscale-80;
	border-radius: 5px;
	user-select: none;
}

.switch {
	width: 40px;
	height: 22px;

	position: relative;
	margin-left: 5px;

	user-select: none;
}

.slider {
	position: absolute;
	cursor: pointer;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	background-color: $color-grayscale-90;
	transition: 0.3s;
	border-radius: 34px;
}

.slider:before {
	position: absolute;
	content: '';
	height: 16px;
	width: 16px;
	left: 3px;
	top: 3px;
	background-color: white;
	transition: 0.3s;
	border-radius: 50%;
}

.checkbox {
	opacity: 0;
	width: 0;
	height: 0;
	visibility: hidden;

	&:checked + .slider {
		background-color: $color-primary;
	}

	&:checked + .slider:before {
		transform: translateX(18px);
	}
}
</style>
