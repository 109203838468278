<template>
	<button
		class="action-button"
		:class="[{ '-small': props.small }, `-${props.variant}`]"
		:type="props.type"
		:disabled="props.disabled"
	>
		<span class="label" :class="{ '-loading': props.loading }">
			<slot></slot>
		</span>
		<div class="loading" :class="`-${props.variant}`" v-if="props.loading">
			<div></div>
			<div></div>
			<div></div>
			<div></div>
		</div>
	</button>
</template>

<script setup>
import { defineProps } from 'vue'

const props = defineProps({
	variant: {
		type: String,
		default: 'primary',
	},
	type: {
		type: String,
		default: 'submit',
	},
	disabled: {
		type: Boolean,
		default: false,
	},
	loading: {
		type: Boolean,
		default: false,
	},
	small: {
		type: Boolean,
		default: false,
	},
})
</script>

<style lang="scss" scoped>
.action-button {
	min-height: 48px;

	position: relative;
	padding: 0 40px;

	@include font-h200;
	color: #ffffff;
	line-height: 0;

	background: $color-primary;
	border: none;
	border-radius: 8px;

	outline: none;
	cursor: pointer;
	transition: box-shadow 0.1s ease, background 0.05s ease;

	&:hover:not([disabled]) {
		background: $color-primary-dark;
	}

	&:focus {
		box-shadow: 0px 0px 0px 4px $color-primary-10;
	}

	&:disabled {
		background: $color-grayscale-20;
		cursor: default;
	}

	&.-small {
		min-height: 32px;
		padding: 0 15px;
		font-size: 13px;
	}

	&.-warning {
		background: $color-warning;

		&:hover:not([disabled]) {
			background: darken($color-warning, 5);
		}

		&:focus {
			box-shadow: 0px 0px 0px 4px rgba($color-warning, 0.2);
			background: darken($color-warning, 5);
		}

		&:disabled {
			color: $color-grayscale-20;
		}
	}

	&.-orange {
		background: $color-orange;

		&:hover:not([disabled]) {
			background: darken($color-orange, 5);
		}

		&:focus {
			box-shadow: 0px 0px 0px 4px rgba($color-orange, 0.2);
			background: darken($color-orange, 5);
		}

		&:disabled {
			color: $color-grayscale-20;
		}
	}
}

.label {
	&.-loading {
		opacity: 0;
	}
}

.loading {
	width: 25px;
	height: 25px;

	position: absolute;
	left: 50%;
	top: 50%;
	margin-left: -12.5px;
	margin-top: -12.5px;
}

.loading.-secondary div {
	border: 2px solid $color-primary;
	border-color: $color-primary transparent transparent transparent;
}

.loading div {
	box-sizing: border-box;
	display: block;
	position: absolute;
	width: 20px;
	height: 20px;
	margin: 2px;
	border: 2px solid #fff;
	border-radius: 50%;
	animation: loading 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
	border-color: #fff transparent transparent transparent;
}

.loading div:nth-child(1) {
	animation-delay: -0.45s;
}

.loading div:nth-child(2) {
	animation-delay: -0.3s;
}

.loading div:nth-child(3) {
	animation-delay: -0.15s;
}

@keyframes loading {
	0% {
		transform: rotate(0deg);
	}

	100% {
		transform: rotate(360deg);
	}
}
</style>
