<template>
	<div class="versions">
		<div class="top">
			<div class="name">Versions</div>
			<div class="line"></div>
			<SelectInput
				id="product"
				type="number"
				label="Product:"
				v-model="state.product"
				:options="productOptions"
				@update:modelValue="state.generatedUrl = null"
			/>
		</div>
		<div class="actions">
			<router-link :to="`/versions/new/${state.product}`">
				<ActionButton class="new-version">Create a new version</ActionButton>
			</router-link>
		</div>
		<div class="loading" v-if="state.loading">
			<div class="spinner">
				<div class="bounce1"></div>
				<div class="bounce2"></div>
				<div class="bounce3"></div>
			</div>
		</div>
		<TableView v-if="!state.loading" :scrollable="false">
			<template v-slot:header> {{ productName }} Versions </template>
			<template v-slot:content>
				<TableRow
					v-for="version in state.versions"
					:key="version.id"
					:label="version.version"
					value=""
					:subvalue="version.public ? `Released on ${version.released_at}` : 'Not public'"
				>
					<router-link :to="`/versions/edit/${state.product}/${version.id}`">
						<ActionButton :small="true">Edit</ActionButton>
					</router-link>
				</TableRow>
			</template>
		</TableView>
	</div>
</template>

<script setup>
import api from '../api'
import SelectInput from '../components/SelectInput.vue'
import TableRow from '../components/TableRow.vue'
import TableView from '../components/TableView.vue'
import ActionButton from '../components/ActionButton.vue'
import { reactive, computed, watchEffect } from 'vue'
import { useStore } from 'vuex'

const store = useStore()
const state = reactive({
	product: null,
	versions: [],
	loading: true,
})

const products = computed(() => {
	return store.state.products.filter((product) => product.public)
})

const productName = computed(() => {
	if (!state.product) {
		return ''
	}
	return products.value.find((product) => product.id === state.product).name
})

const productOptions = computed(() => {
	return products.value.map((product) => {
		return {
			name: product.name,
			value: product.id,
		}
	})
})

const loadVersions = (id) => {
	state.loading = true
	api.get(`dashboard/product/${id}/versions`)
		.then(({ data }) => {
			state.versions = data
			state.loading = false
		})
		.catch((error) => console.log(error))
}

watchEffect(() => {
	if (products.value.length > 0) {
		state.product = products.value[0].id
	}
})

watchEffect(() => {
	if (state.product === null) {
		return
	}
	loadVersions(state.product)
})
</script>

<style lang="scss" scoped>
.product {
	padding-bottom: 100px;
}

.top {
	display: flex;
	align-items: center;
	margin-bottom: 40px;
}

.name {
	@include font-h800;
	color: #ffffff;
}

.line {
	height: 2px;

	flex-grow: 1;
	margin-left: 45px;

	background: $color-grayscale-70;
}

.select-input {
	margin-left: 30px;
	margin-top: -30px;
}

.actions {
	display: flex;
	justify-content: flex-end;
}

.new-version {
	margin-bottom: 20px;
}

.loading {
	width: 100%;
	height: 300px;

	display: flex;
	justify-content: center;
	align-items: center;
}

.spinner {
	width: 70px;
	text-align: center;
}

.spinner > div {
	width: 18px;
	height: 18px;
	background-color: $color-grayscale-50;

	border-radius: 100%;
	display: inline-block;
	-webkit-animation: sk-bouncedelay 1.4s infinite ease-in-out both;
	animation: sk-bouncedelay 1.4s infinite ease-in-out both;
}

.spinner .bounce1 {
	animation-delay: -0.32s;
}

.spinner .bounce2 {
	animation-delay: -0.16s;
}

@keyframes sk-bouncedelay {
	0%,
	80%,
	100% {
		-webkit-transform: scale(0);
		transform: scale(0);
	}
	40% {
		-webkit-transform: scale(1);
		transform: scale(1);
	}
}

.icon {
	margin-right: 10px;
}
</style>
