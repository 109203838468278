<template>
	<div class="version-form">
		<div class="top">
			<div class="name">{{ isEditing ? 'Edit' : 'Create' }} {{ productName }} version</div>
			<div class="line"></div>
		</div>
		<div class="loading" v-if="state.loading">
			<div class="spinner">
				<div class="bounce1"></div>
				<div class="bounce2"></div>
				<div class="bounce3"></div>
			</div>
		</div>
		<form class="form" @submit.prevent="submit" v-if="!state.loading">
			<TextInput
				id="version"
				v-model.trim="state.version_number"
				label="Version number:"
				placeholder="1.0"
				:form="state.form"
				:required="true"
				:autofocus="true"
			/>
			<TextInput
				id="min_os_version"
				v-model.trim="state.min_os_version"
				label="Min macOS version:"
				placeholder="10.14.4"
				:form="state.form"
				:required="true"
			/>
			<TextInput
				id="size"
				type="number"
				v-model.trim="state.size"
				label="Size:"
				placeholder="0"
				:form="state.form"
				:required="true"
			/>
			<TextInput
				id="ed_signature"
				v-model.trim="state.ed_signature"
				label="ED signature:"
				placeholder="RM2Du4H..."
				:form="state.form"
			/>
			<TextInput
				id="dsa_signature"
				v-model.trim="state.dsa_signature"
				label="DSA signature:"
				placeholder="MCwCFDs..."
				:form="state.form"
			/>
			<SelectInput
				id="public"
				type="number"
				label="Status:"
				v-model="state.is_public"
				:options="[
					{ name: 'Released', value: 1 },
					{ name: 'Not public', value: 0 },
				]"
			/>
			<ActionButton :loading="state.form.loading">{{ isEditing ? 'Update' : 'Create' }}</ActionButton>
		</form>
	</div>
</template>

<script setup>
import api from '../api'
import FormHandler from '../helpers/FormHandler'
import SelectInput from '../components/SelectInput.vue'
import ActionButton from '../components/ActionButton.vue'
import TextInput from '../components/TextInput.vue'

import { defineProps, reactive, computed, onMounted } from 'vue'
import { useStore } from 'vuex'
import { useRouter } from 'vue-router'

const router = useRouter()
const store = useStore()

const props = defineProps({
	product: String,
	version: {
		type: String,
		default: null,
	},
})

const state = reactive({
	version_number: '',
	min_os_version: '10.14.4',
	size: null,
	ed_signature: '',
	dsa_signature: '',
	is_public: 1,
	loading: false,
	form: new FormHandler(),
})
const productId = computed(() => {
	return parseInt(props.product)
})

const versionId = computed(() => {
	if (!props.version) {
		return null
	}
	return parseInt(props.version)
})

const isEditing = computed(() => {
	return versionId.value !== null
})

const products = computed(() => {
	return store.state.products.filter((product) => product.public)
})

const productName = computed(() => {
	if (products.value.length === 0) {
		return null
	}
	return products.value.find((product) => product.id === productId.value).name
})

const submit = () => {
	if (state.form.loading) {
		return
	}
	let url = `dashboard/product/${productId.value}/versions`
	if (isEditing.value) {
		url = `dashboard/product/${productId.value}/versions/${versionId.value}`
	}
	state.form
		.submit(url, {
			version: state.version_number,
			min_os_version: state.min_os_version,
			size: state.size,
			ed_signature: state.ed_signature,
			dsa_signature: state.dsa_signature,
			public: state.is_public,
		})
		.then(() => {
			router.push('/versions')
		})
		.catch((error) => {
			console.log(error)
		})
}
const loadVersion = () => {
	api.get(`dashboard/product/${productId.value}/versions/${versionId.value}`)
		.then(({ data }) => {
			state.version_number = data.version
			state.min_os_version = data.min_os_version
			state.size = data.size
			state.ed_signature = data.ed_signature
			state.dsa_signature = data.dsa_signature
			state.is_public = data.public
			state.loading = false
		})
		.catch((error) => console.log(error))
}
onMounted(() => {
	if (isEditing.value) {
		state.loading = true
		loadVersion()
	}
})
</script>

<style lang="scss" scoped>
.top {
	display: flex;
	align-items: center;
	margin-bottom: 40px;
}

.name {
	@include font-h800;
	color: #ffffff;
}

.line {
	height: 2px;

	flex-grow: 1;
	margin-left: 45px;

	background: $color-grayscale-70;
}

.select-input {
	margin-bottom: 20px;
}

.action-button {
	margin-top: 30px;
}

.input-group {
	display: flex;
}

.text-input {
	max-width: 850px;

	margin-right: 20px;
}

.loading {
	width: 100%;
	height: 300px;

	display: flex;
	justify-content: center;
	align-items: center;
}

.spinner {
	width: 70px;
	text-align: center;
}

.spinner > div {
	width: 18px;
	height: 18px;
	background-color: $color-grayscale-50;

	border-radius: 100%;
	display: inline-block;
	-webkit-animation: sk-bouncedelay 1.4s infinite ease-in-out both;
	animation: sk-bouncedelay 1.4s infinite ease-in-out both;
}

.spinner .bounce1 {
	animation-delay: -0.32s;
}

.spinner .bounce2 {
	animation-delay: -0.16s;
}

@keyframes sk-bouncedelay {
	0%,
	80%,
	100% {
		-webkit-transform: scale(0);
		transform: scale(0);
	}
	40% {
		-webkit-transform: scale(1);
		transform: scale(1);
	}
}
</style>
