import api from '../api'

export default class FormHandler {
	constructor() {
		this.errors = {}
		this.message = ''
		this.loading = false
	}

	submit(url, data, method = 'post') {
		this.loading = true
		return new Promise((resolve, reject) => {
			api.request({
				method,
				url,
				data,
			})
				.then((response) => {
					this.clearErrors()
					resolve(response)
				})
				.catch((error) => {
					if (error.response) {
						if (error.response.data && error.response.data.errors) {
							this.setErrors(error.response.data.errors)
						}
						reject(error.response)
					} else {
						console.error('No response in error object', error)
					}
				})
				.finally(() => {
					this.loading = false
				})
		})
	}

	hasError(field) {
		return Object.hasOwn(this.errors, field)
	}

	getError(field) {
		if (!this.hasError(field)) {
			return null
		}
		// eslint-disable-next-line security/detect-object-injection
		return this.errors[field][0]
	}

	clearError(field) {
		// eslint-disable-next-line security/detect-object-injection
		delete this.errors[field]
	}

	setErrors(errors) {
		this.errors = errors
	}

	clearErrors() {
		this.errors = []
	}
}
