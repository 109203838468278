<template>
	<div class="sso-auth">
		<div class="form-wrapper">
			<div class="col-400">
				<div class="info">{{ state.error }}</div>
			</div>
		</div>
	</div>
</template>

<script setup>
import { onMounted, reactive } from 'vue'
import api from '../api'
import { useRoute, useRouter } from 'vue-router'
import { useStore } from 'vuex'

const router = useRouter()
const route = useRoute()
const store = useStore()

const state = reactive({
	error: '',
})

const login = () => {
	const token = route.hash.replace('#', '')

	store.commit('setToken', token)

	api.get('dashboard/user')
		.then(({ data }) => {
			store.commit('setUser', data.user)
			store.commit('setProducts', data.products)
			router.push({ name: 'home' })
		})
		.catch((error) => {
			state.error = 'An error has occurred. Please contact support if it persists.'
			// eslint-disable-next-line no-console
			console.error('Request error: ' + error)
		})
}

onMounted(() => {
	if (route.hash.length > 0) {
		login()
	} else {
		router.push({ name: 'login' })
	}
})
</script>

<style lang="scss" scoped>
.sso-auth {
	min-height: 100%;

	display: flex;
	flex-direction: column;
	justify-content: center;
}

.info {
	color: white;
}
</style>
